<template>
  <div>
    <h1 class="text-xl font-bold mb-4">Agregar tarea</h1>
    <textarea
      v-model="newTodo"
      type="text"
      class="textarea textarea-bordered textarea-lg w-full"
      placeholder="Agregar nueva tarea"
    />
    <button class="btn btn-neutral mt-4 w-full" @click="addTodo">
      Agregar Tarea
    </button>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        newTodo: '',
      }
    },
    methods: {
      addTodo() {
        if (this.newTodo.trim() !== '') {
          this.$emit('add-todo', this.newTodo.trim())
          this.newTodo = ''
        }
      },
    },
  }
  </script>