<template>
  <div class="mb-4 ">
    <div class="btn-group btn-group-vertical lg:btn-group-horizontal ">
      <button
        class="btn"
        :class="{
          'btn-active': filter === 'all',
        }"
        @click="setFilter('all')"
      >
        Todas
      </button>
      <button
        class="btn"
        :class="{
          'btn-active': filter === 'active',
        }"
        @click="setFilter('active')"
      >
        Activas
      </button>
      <button
        class="btn"
        :class="{
          'btn-active': filter === 'completed',
        }"
        @click="setFilter('completed')"
      >
        Completadas
      </button>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        filter: 'all',
      }
    },
    methods: {
      setFilter(filter) {
        this.filter = filter
        this.$emit('filter-changed', filter)
      },
    },
  }
  </script>