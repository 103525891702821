<template>
  <div class="flex flex-col min-h-screen">
  <main class="flex-grow">
    <div class="container mx-auto mt-2 mb-10">
    <div class="navbar bg-base-100 shadow-2xl rounded-full">
      <div class="flex-1">
        <a class="btn btn-ghost text-xl">To Do</a>
      </div>
      <div class="flex-none">
        <label class="flex cursor-pointer gap-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="5" />
            <path
              d="M12 1v2M12 21v2M4.2 4.2l1.4 1.4M18.4 18.4l1.4 1.4M1 12h2M21 12h2M4.2 19.8l1.4-1.4M18.4 5.6l1.4-1.4"
            />
          </svg>
          <input type="checkbox" value="dark" class="toggle theme-controller" />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M21 12.79A9 9 0 1 1 11.21 3 7 7 0 0 0 21 12.79z"></path>
          </svg>
        </label>
      </div>
    </div>
  </div>
  <todoList />
  </main>  
  <TodoFooter/>
</div>
</template>

<script>
import todoList from "./components/todoList.vue";
import TodoFooter from "./components/todoFooter.vue";

export default {
  name: "App",
  components: {
    todoList,
    TodoFooter
  },
};
</script>
