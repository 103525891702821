<template>
  <div class="card bg-base-100 shadow-xl">
    <div class="card-body">
      <div class="form-control" v-if="!isEditing">
        <label class="label cursor-pointer">
          <p class="text-lg word-wrap" :class="{ 'line-through text-gray-500': todo.completed, }">{{ todo.text }}</p>
          <input
            type="checkbox"
            class="checkbox"
            :checked="todo.completed"
            @change="toggleTodo(todo)"
          />
        </label>
      </div>
      <div v-else>
        <textarea
          v-model="editedText"
          type="text"
          class="textarea textarea-bordered textarea-lg w-full"
          @keyup.enter="saveEdit"
        />
      </div>
      <div class="card-actions">
        <button v-if="!isEditing" class="btn btn-ghost btn-sm" @click="startEdit">
          Editar
        </button>
        <button v-else class="btn btn-active btn-ghost btn-sm" @click="saveEdit">
          Guardar
        </button>
        <button v-if="!isEditing" class="btn btn-ghost btn-sm" @click="removeTodo(todo)">
          Eliminar
        </button>
        <button v-else class="btn btn-error btn-sm" @click="cancelEdit">
          Cancelar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    todo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isEditing: false,
      editedText: '',
    };
  },
  methods: {
    toggleTodo(todo) {
      this.$emit('toggle-todo', todo);
    },
    removeTodo(todo) {
      this.$emit('remove-todo', todo);
    },
    startEdit() {
      this.isEditing = true;
      this.editedText = this.todo.text;
    },
    saveEdit() {
      if (this.editedText.trim() !== '') {
        this.$emit('edit-todo', {
          ...this.todo,
          text: this.editedText.trim(),
        });
        this.isEditing = false;
        this.editedText = '';
      }
    },
    cancelEdit() {
      this.isEditing = false;
      this.editedText = '';
    },
  },
};
</script>

<style>
.card-body p {
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>