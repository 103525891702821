<template>
    <div class="join mt-4">
      <button
        class="join-item btn"
        :disabled="currentPage === 1"
        @click="changePage(currentPage - 1)"
      >
        Anterior
      </button>
      <button
        v-for="page in pages"
        :key="page"
        class="join-item btn"
        :class="{ 'btn-active': page === currentPage }"
        @click="changePage(page)"
      >
        {{ page }}
      </button>
      <button
        class="join-item btn"
        :disabled="currentPage === totalPages"
        @click="changePage(currentPage + 1)"
      >
        Siguiente
      </button>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      totalItems: {
        type: Number,
        required: true,
      },
      itemsPerPage: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        currentPage: 1,
      };
    },
    computed: {
      totalPages() {
        return Math.ceil(this.totalItems / this.itemsPerPage);
      },
      pages() {
        const pages = [];
        for (let i = 1; i <= this.totalPages; i++) {
          pages.push(i);
        }
        return pages;
      },
    },
    methods: {
      changePage(page) {
        this.currentPage = page;
        this.$emit('page-changed', page);
      },
    },
  };
  </script>