<template>
  <div class="container mx-auto max-w-4xl">
    <div class="flex flex-col md:flex-row md:items-start">
      <div class="md:w-1/2 p-4">
        <div class="bg-base-100 shadow-xl rounded-lg p-6">
          <TodoForm @add-todo="addTodo" />
        </div>
      </div>
      <div class="md:w-1/2 p-4 text-center">
        <TodoFilter @filter-changed="handleFilterChange" />
        <div v-if="filteredTodos.length === 0" class="text-center">
          No hay tareas pendientes
        </div>
        <div v-else class="grid grid-cols-1 gap-4">
          <TaskCard
            v-for="todo in paginatedTodos"
            :key="todo.id"
            :todo="todo"
            @toggle-todo="toggleTodo"
            @remove-todo="removeTodo"
            @edit-todo="editTodo"
          />
        </div>
        <Pagination
          :total-items="filteredTodos.length"
          :items-per-page="itemsPerPage"
          @page-changed="handlePageChange"
        />
      </div>

    </div>
  </div>
</template>

<script>
import TodoForm from "./todoForm.vue";
import TodoFilter from "./todoFilter.vue";
import TaskCard from "./todoTask.vue";
import Pagination from "./todoPagination.vue";

export default {
  components: {
    TodoForm,
    TodoFilter,
    TaskCard,
    Pagination,
  },
  data() {
    return {
      todos: [],
      filter: "all",
      currentPage: 1,
      itemsPerPage: 4,
    };
  },
  computed: {
    filteredTodos() {
      if (this.filter === "all") {
        return this.todos;
      } else if (this.filter === "active") {
        return this.todos.filter((todo) => !todo.completed);
      } else {
        return this.todos.filter((todo) => todo.completed);
      }
    },
    paginatedTodos() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.filteredTodos.slice(startIndex, endIndex);
    },
  },
  methods: {
    addTodo(newTodo) {
      this.todos.push({
        id: Date.now(),
        text: newTodo,
        completed: false,
      });
    },
    toggleTodo(todo) {
      const updatedTodo = {
        ...todo,
        completed: !todo.completed,
      };
      this.todos = this.todos.map((t) => (t.id === todo.id ? updatedTodo : t));
    },
    removeTodo(todoToRemove) {
      this.todos = this.todos.filter((todo) => todo.id !== todoToRemove.id);
    },
    handleFilterChange(filter) {
      this.filter = filter;
      this.currentPage = 1; // Reiniciar la página al cambiar el filtro
    },
    handlePageChange(page) {
      this.currentPage = page;
    },
    getTodosFromLocalStorage() {
    const todosFromStorage = localStorage.getItem("todos");
    return todosFromStorage ? JSON.parse(todosFromStorage) : [];
    },
    editTodo(updatedTodo) {
    this.todos = this.todos.map((todo) =>
      todo.id === updatedTodo.id ? updatedTodo : todo
    );
  },
  },
  created() {
    this.todos = this.getTodosFromLocalStorage();
  },
  updated() {
    localStorage.setItem("todos", JSON.stringify(this.todos));
  },
};
</script>
